//
// This application was created by Antoine DELRUE - Ob1lan
// All rights reserved © 2024
//

import React, { Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";
import "leaflet/dist/leaflet.css";
import "flag-icons/css/flag-icons.min.css";
import { jwtDecode } from "jwt-decode";
import GlobalToastManager from "./components/ToastManager";
import { UserProvider } from "./utils/userUtils";

// Lazy-loaded components
const ProtectedRoute = React.lazy(() => import("./utils/ProtectedRoute"));
const CustomNavbar = React.lazy(() => import("./components/Navbar"));
const Login = React.lazy(() => import("./Login"));
const Dashboard = React.lazy(() => import("./Dashboard"));
const AdminUser = React.lazy(() => import("./AdminUsers"));
const AdminCompanies = React.lazy(() => import("./AdminCompanies"));
const AdminSMTP = React.lazy(() => import("./AdminSMTP"));
const AdminSLA = React.lazy(() => import("./AdminSLA"));
const AdminDuty = React.lazy(() => import("./AdminDuty"));
const LiveClock = React.lazy(() => import("./components/LiveClock"));
const NotFound = React.lazy(() => import("./components/404"));
const Profile = React.lazy(() => import("./Profile"));
const SetPassword = React.lazy(() => import("./components/SetPassword"));
const Unauthorized = React.lazy(() => import("./components/Unauthorized"));

// Utility function to check if the JWT token has expired
function isExpired(token) {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Failed to decode JWT", error);
    return true;
  }
}

const RedirectToDashboard = () => {
  const token = localStorage.getItem("token");
  const isTokenExpired = token ? isExpired(token) : true;

  return !isTokenExpired ? (
    <Navigate to="/dashboard" />
  ) : (
    <Navigate to="/login" />
  );
};

const RedirectIfAuthenticated = () => {
  const token = localStorage.getItem("token");
  const isTokenExpired = token ? isExpired(token) : true;

  return !isTokenExpired ? <Navigate to="/dashboard" /> : <Login />;
};

function App() {
  // eslint-disable-next-line no-unused-vars
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme") || "light";
    document.body.setAttribute("data-bs-theme", currentTheme);
  }, []);

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === "light" ? "dark" : "light";
      localStorage.setItem("theme", newTheme);
      document.body.setAttribute("data-bs-theme", newTheme);
      return newTheme;
    });
  };

  return (
    <Router>
      <UserProvider>
        <Suspense fallback={<LoadingScreen />}>
          <div className="d-flex flex-column min-vh-100" data-theme={theme}>
            <div className="flex-grow-1">
              <CustomNavbar toggleTheme={toggleTheme} />
              <Routes>
                <Route path="/" element={<RedirectToDashboard />} />
                <Route path="/login" element={<RedirectIfAuthenticated />} />
                <Route
                  path="/profile"
                  element={<ProtectedRoute component={Profile} />}
                />
                <Route
                  path="/admin-users"
                  element={<ProtectedRoute component={AdminUser} adminOnly />}
                />
                <Route
                  path="/admin-companies"
                  element={
                    <ProtectedRoute component={AdminCompanies} adminOnly />
                  }
                />
                <Route
                  path="/admin-duty"
                  element={<ProtectedRoute component={AdminDuty} adminOnly />}
                />
                <Route
                  path="/admin-smtp"
                  element={<ProtectedRoute component={AdminSMTP} adminOnly />}
                />
                <Route
                  path="/admin-sla"
                  element={<ProtectedRoute component={AdminSLA} adminOnly />}
                />
                <Route
                  path="/dashboard"
                  element={<ProtectedRoute component={Dashboard} />}
                />{" "}
                <Route path="/set-password" element={<SetPassword />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="*" element={<NotFound />} />{" "}
              </Routes>
            </div>
            <footer className="text-center text-lg-start mt-auto">
              <hr />
              <div className="text-center pb-3">
                {process.env.REACT_APP_FOOTER} | <LiveClock />
              </div>
            </footer>
          </div>
        </Suspense>
      </UserProvider>
      <GlobalToastManager />
    </Router>
  );
}

export default App;
