import React, { useEffect, useState } from "react";
import { ToastContainer, Toast } from "react-bootstrap";
import useToast from "../utils/useToast";

const GlobalToastManager = () => {
  const [globalMessage, setGlobalMessage] = useState("");
  const [globalType, setGlobalType] = useState("success");
  const [showGlobalToast, setShowGlobalToast] = useState(false);

  const { registerGlobalToast } = useToast();

  useEffect(() => {
    const handleGlobalToast = (message, type) => {
      setGlobalMessage(message);
      setGlobalType(type);
      setShowGlobalToast(true);
    };

    // Register the global toast handler
    registerGlobalToast(handleGlobalToast);

    // Cleanup to prevent stale references
    return () => registerGlobalToast(null);
  }, [registerGlobalToast]);

  return (
    <ToastContainer className="p-3" position="bottom-right">
      <Toast
        onClose={() => setShowGlobalToast(false)}
        show={showGlobalToast}
        delay={5000}
        autohide
        className={`bg-${globalType} text-white`}
      >
        <Toast.Header className={`text-white bg-${globalType}`}>
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{globalMessage}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default GlobalToastManager;
