import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import propTypes from "prop-types";

// Create User Context
const UserContext = createContext();

/**
 * Utility function to decode the JWT token and retrieve user details.
 * @returns {object} User details including prefix and role.
 * @throws Error if token is missing or expired.
 */
export const getUserDetails = () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token not found");
    const decodedToken = jwtDecode(token);

    // Check token expiration
    if (decodedToken.exp * 1000 < Date.now()) {
      console.error("Token expired");
      localStorage.removeItem("token");
      throw new Error("Token expired");
    }

    return {
      prefix: decodedToken.ticketRefPrefix || "",
      role: decodedToken.role || "",
    };
  } catch (error) {
    console.error("Error decoding token:", error);
    return { prefix: "", role: "" }; // Fallback to empty values
  }
};

/**
 * Provider component to set up the User Context.
 * @param {ReactNode} children - React children components.
 */
export const UserProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({
    prefix: "",
    role: "",
  });

  useEffect(() => {
    const details = getUserDetails();
    setUserDetails(details);
  }, []);

  return (
    <UserContext.Provider value={userDetails}>{children}</UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: propTypes.node.isRequired,
};

/**
 * Hook to access the User Context.
 * @returns {object} User details from context.
 */
export const useUser = () => useContext(UserContext);
