import { useState, useCallback } from "react";

let globalToastCallback = null;

const useToast = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [toastType, setToastType] = useState("success");

  const displayToast = useCallback((message, type = "success") => {
    setToastContent(message);
    setToastType(type);
    setShowToast(true);

    // Trigger global toast if registered
    if (globalToastCallback) {
      globalToastCallback(message, type);
    }
  }, []);

  const hideToast = () => {
    setShowToast(false);
  };

  const registerGlobalToast = useCallback((callback) => {
    globalToastCallback = callback;
  }, []);

  return {
    showToast,
    toastContent,
    toastType,
    displayToast,
    hideToast,
    registerGlobalToast,
  };
};

export default useToast;
